import 'bootstrap/dist/css/bootstrap.min.css';
import  "./css/table.css";
import "rsuite/dist/rsuite.min.css";
import  "../../src/gitprofile/dist/style.css";
import { Analytics } from '@vercel/analytics/react';

export default function MyApp({ Component, pageProps }) {
    return <><Analytics /><Component {...pageProps} /></>;
};

